import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom/client'
import React from 'react'
import App from './App'
import './index.css'

if (process.env.REACT_APP_LOG_LEVEL !== 'dev') {
  console.log = function () {}
}
console.log('process.env.REACT_APP_LOG_LEVEL', process.env.REACT_APP_LOG_LEVEL)

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
)

reportWebVitals()
